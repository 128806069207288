<template>
  <div class="config-table">
    <b-table :items="configs" :fields="fields" responsive="sm" small>
      <template #cell(stt)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(category)="data">
        <b-form-input
          v-if="configId == data.item._id"
          v-model="category"
          type="text"
          placeholder="Category"
        />
        <p v-else>{{ data.value }}</p>
      </template>

      <template #cell(key)="data">
        <b-form-input
          v-if="configId == data.item._id"
          v-model="key"
          type="text"
          placeholder="Key"
        />
        <p v-else>{{ data.value }}</p>
      </template>

      <template #cell(type)="data">
        <b-form-select
          v-if="configId == data.item._id"
          v-model="type"
          :options="types"
        />
        <p v-else>{{ getTypeName(data.value) }}</p>
      </template>

      <template #cell(value)="data">
        <b-form-checkbox
          v-if="configId == data.item._id && type == 'boolean'"
          v-model="value"
          class="custom-control-primary"
          name="check-button"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="CheckIcon" />
          </span>
        </b-form-checkbox>
        <b-form-textarea
          v-else-if="configId == data.item._id && type === 'object'"
          v-model="value"
          :type="type == 'string' ? 'text' : type"
          rows="6"
          placeholder="Value"
        />
        <b-form-input
          v-else-if="configId == data.item._id"
          v-model="value"
          :type="type == 'string' ? 'text' : type"
          placeholder="Value"
        />
        <p v-else>{{ JSON.stringify(data.value).length > 40 ? reduceTextLength(data.value) : data.value }}</p>
      </template>

      <template #cell(action)="data">
        <div class="d-flex">
          <b-button
            @click="editOrUpdateConfig(data.index)"
            size="sm"
            style="margin-right: 5px"
            variant="primary"
          >
            {{ configId == data.item._id ? "Save" : "Edit" }}
          </b-button>
          <!-- <b-button
            size="sm"
            variant="danger"
            @click="confirmDelete(data.item._id)"
          >
            {{ configId == data.item._id ? "Cancel" : "Delete" }}
          </b-button> -->
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import service from "../../service";
export default {
  props: {
    configs: { type: Array, required: true },
    types: { type: Array, required: true },
  },
  data() {
    return {
      fields: [
        "stt",
        "category",
        "key",
        "type",
        "value",
        { key: "action", label: "" },
      ],
      configId: null,
      key: null,
      category: null,
      value: null,
      type: null,
    };
  },
  watch: {
    type: function (newValue) {
      if (newValue == "boolean" && (typeof this.value) != 'boolean') {
        this.value = false;
      }
    },
  },
  methods: {
    async editOrUpdateConfig(index) {
      if (
        !this.configId ||
        (this.configId && this.configId != this.configs[index]._id)
      ) {
        this.configId = this.configs[index]._id;
        this.key = this.configs[index].key;
        this.category = this.configs[index].category;
        this.value = this.configs[index].type === 'object' ? JSON.stringify(this.configs[index].value, undefined, 4) : this.configs[index].value;
        this.type = this.configs[index].type;
      } else {
        if (this.type === 'object') {
          this.value = JSON.parse(this.value)
        }
        let dataUpdate = {
          ...this.configs[index],
          key: this.key,
          category: this.category,
          value: this.type == "number" ? parseFloat(this.value) : this.value,
          type: this.type,
        };
        let response = await service.update(dataUpdate);
        if (response.data.type == "SUCCESS") {
          this.resetData();
          this.$emit("getList");
        }
      }
    },
    async cancelOrDelete(id) {
      if (this.configId == id) {
        this.resetData();
      } else {
        await service.delete({ id: id });
        this.$emit("getList");
      }
    },
    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.cancelOrDelete(id);
          }
        });
    },
    resetData() {
      this.configId = null;
      this.key = null;
      this.category = null;
      this.value = null;
    },
    getTypeName(value) {
      let findType = this.types.find((type) => type.value == value);
      return (findType && findType.text) || "";
    },
    reduceTextLength(text){
      text = JSON.stringify(text);
      return text.slice(0, 40)+"..."
    }
  },
};
</script>
<style lang="scss">
.config-table {
  table {
    td {
      padding-top: 0.72rem !important;
      padding-bottom: 0.72rem !important;
      p {
        margin: unset;
      }
    }
  }
}
</style>
